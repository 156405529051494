import Cookies from 'universal-cookie';
import { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Image,
  Text,
  VStack,
} from '@chakra-ui/react';

const Profile = () => {
  const [displayname, setDisplayName] = useState('');
  const [handle, setHandle] = useState('');
  const [createAt, setCreateAt] = useState('');
  const [avatarUrl, setAvatarUrl] = useState('');
  const cookies = new Cookies();
  useEffect(()=>{
    const token = cookies.get('Token');
    const urlParams = new URLSearchParams(window.location.search);
    const authToken = urlParams.get('authToken');
    const userName = urlParams.get('userName')
    console.log(authToken);
    if(authToken){
      cookies.set('Token', authToken);
      axios.post('https://agelessrepublic.com/api/auth/saveToken', {token :authToken, name : userName})
      .then((res) => {
        setDisplayName(res.data.username);
        setCreateAt(res.data.createAt);
        setHandle(res.data.handle);
        setAvatarUrl(res.data.avatarUrl);
      })
      .catch((e)=>{
        console.log(e);
      });
    }
  }, []);


  return (
     <Box
      w={{ base: 'full', md: 'md' }}
      p={{ base: 8, md: 10 }}
      mb="80px"
      mt={{ base: 20, md: '10vh' }}
      mx="auto"
      border={{ base: 'none', md: '1px' }}
      borderColor={{ base: '', md: 'gray.300' }}
      borderRadius="10"
    >
      <VStack spacing="4" align="center" w="full">
        
          <Image
            justifyContent="center"
            src={avatarUrl}
            objectFit="cover"
            alt="profile"
            borderRadius="full"
            boxSize={{ base: "100px", md: "150px", xl: "200px" }}
          />
        <VStack spacing="1" align="flex-start">
          <Text paddingBlock="1rem" fontSize={{ base: "xl", md: "2xl", xl: "3xl" }} fontWeight="bold">
            {"DisplayName : " + displayname}
          </Text>
          <Text paddingBlock="1rem" fontSize={{ base: "xl", md: "2xl", xl: "3xl" }} fontWeight="bold">
            {"Handle : " + handle}
          </Text>
          <Text paddingBlock="1rem" fontSize={{ base: "xl", md: "2xl", xl: "3xl" }} fontWeight="bold">
            {"Created At : " + createAt}
          </Text>
          </VStack>
      </VStack>
    </Box>
  );
};

export default Profile;
