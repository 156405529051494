import './App.css';
import { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Homepage from './components/Homepage';
import Navbar from './components/Navbar.js';
import Notifications from './components/Notifications';
import Profile from './components/Profile';
import HousingInfo from './components/HousingInfo';
import { ChakraProvider } from '@chakra-ui/react';
import Error from './components/Error';
import Admin from './components/Admin';

function App() {
  const location = useLocation();
  const [forceNavbarRerender, setForceNavbarRerender] = useState(false);

  useEffect(() => {
    setForceNavbarRerender(true);
    return () => {
      setForceNavbarRerender(false);
    };
  }, [location]);

  return (
    <ChakraProvider>
      <Navbar />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/" element={<HousingInfo />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/*" element={<Error />} />
      </Routes>
    </ChakraProvider>
  );
}

export default App;
