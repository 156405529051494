import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { Text, Box, Flex, Button, HStack } from '@chakra-ui/react';

const Homepage = () => {
  const cookies = new Cookies();
  const token = cookies.get('TOKEN');

  return (
    <Flex
      w={{ md: '80vw', xl: '40vw' }}
      margin="auto"
      mt="20px"
      pt="0"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box className="hidden" as="section" mt="20px">
        <Text fontSize="4xl" fontWeight="bold" textAlign="Center">
          Welcome to Our Game!
        </Text>
      </Box>
    </Flex>
  );
};

export default Homepage;
