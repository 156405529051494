import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { Flex,  Heading,   Button,   Spacer,   HStack,   Box,   IconButton,   useDisclosure,   Collapse,  Drawer,   DrawerOverlay,   DrawerContent,   DrawerCloseButton,   DrawerHeader,   DrawerBody } from '@chakra-ui/react';
import { BellIcon, CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import { useEffect } from 'react';
import { motion, } from 'framer-motion';
const MotionButton = motion(Button);

export default function Navbar() {
  const cookies = new Cookies();
  const token = cookies.get('Token');
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Auto-close the menu on screen resize
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768 && isOpen) {
        onClose();
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isOpen, onClose]);

  const navItemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } }
  };
  const handleItemClick = () => {
    onClose(); // Close the drawer
  };

const ConnectWallet = async () => {
  onClose();
  const appId = "672193137d17e2d2c1a52e1c"
  const redirectionLoginUrl = `https://app.handcash.io/#/authorizeApp?appId=${appId}`;
  window.location.href = redirectionLoginUrl;
  }
  return (
    <Flex
      as="nav"
      p={["5px", "10px"]}
      pb={["10px", "15px"]}
      mt="10px"
      justifyContent="space-between"
      borderBottom="1px solid #E2E8F0"
      position="sticky" // Sticky positioning
      top="0"           // Sticks to the top of the viewport
      zIndex="1000"     // Ensure it stays above other content
      bg="white"        // Background color to avoid transparency on scroll
    >
      <Link to="/">
        <Heading as="h1" mx="20px" mb="5px">
          Ageless Republic
        </Heading>
      </Link>
      <Box display={{ base: "block", md: "none" }} onClick={isOpen ? onClose : onOpen}>
          <IconButton
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label="Toggle navigation"
            variant="ghost"
          />
      </Box>
      {/* Desktop Navigation */}
      <HStack
        spacing="20px"
        display={{ base: "none", md: "flex" }}
        pt="8px"
      >
       {["Home","Game Items", "Leaderboard", "Marketplace"].map((item, index) => (
          <Link to="/" key={item}>
            <MotionButton
              bg="white"
              variants={navItemVariants}
              initial="hidden"
              animate="visible"
              custom={index}
            >
              {item}
            </MotionButton>
          </Link>
        ))}
        
        {token ?
          <>
          <Link to="/notifications">
            <Button colorScheme="blue">
              <BellIcon />
            </Button>
          </Link>
          <Link to="/profile">
            <Button colorScheme="blue">Profile</Button>
          </Link></>
      :  
        <>
            <Button onClick={ConnectWallet} colorScheme="blue">Connect with Handcash Wallet</Button>
        </>
        }
        <Spacer/>
      </HStack>


       {/* Drawer for mobile navigation */}
       <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="xs">
        <DrawerOverlay>
          <DrawerContent width={{ base: "40%", md: "200px" }}>
            <DrawerHeader>Ageless Republic</DrawerHeader>
            <DrawerBody >
              <HStack
                spacing="20px" flexDirection="column"
              >
              {["Home","Game Items", "Leaderboard", "Marketplace"].map((item, index) => (
                  <Link to="/" key={item}>
                    <MotionButton
                      bg="white"
                      variants={navItemVariants}
                      initial="hidden"
                      animate="visible"
                      custom={index}
                      onClick={handleItemClick}
                    >
                      {item}
                    </MotionButton>
                  </Link>
                ))}
                
                {token ?
                  <>
                  <Link to="/notifications">
                    <Button colorScheme="blue" onClick={handleItemClick}>
                      <BellIcon />
                    </Button>
                  </Link>
                  <Link to="/profile">
                    <Button colorScheme="blue" onClick={handleItemClick}>Profile</Button>
                  </Link></>
              :  
                <>
                    <Button colorScheme="blue" onClick={ConnectWallet}>Connect with Handcash Wallet</Button>
                </>
                }
                <Spacer/>
              </HStack>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Flex>
  );
}
